import React from "react";
import { Button, Segment, Message } from "semantic-ui-react";
import { isEmpty } from "../../utils/isEmpty";
import {doesntLookRight} from "../../config/copy";

const getClassName = (button, i) => {
  if (button.color && button.color.includes("color"))
    return `bg ${button.color}`;

  return i === 0 ? "bg color1" : "";
};

const getButtonStyleForBGColor = (button, id) => {
  if (!button.color) return;

  if (!button.color.startsWith("#")) return;

  return `
    #${id} {
      background-color: ${button.color} !important;
    }
    `;
};

const SubmitButtons = ({
  errors,
  buttons,
  handleClick,
  loading,
  submitButtonShouldBeDisabled,
  comment,
}) => {
  comment =
    comment ||
    (buttons.find((b) => b.comment)
      ? buttons.find((b) => b.comment).comment
      : undefined);
  return (
    <Segment basic>
      {buttons.map((b, i) => {
        const id = `${b.text}${i}`;

        return (
          <Button
            key={b.text}
            loading={loading}
            className={getClassName(b, i)}
            primary={i === 0}
            basic={i > 0}
            onClick={() => handleClick(b.link, b.method, b.filename)}
            disabled={
              loading ||
              (b.method === "post" && i === 0 && submitButtonShouldBeDisabled)
            }
            id={id}
          >
            <style>{getButtonStyleForBGColor(b, id)}</style>
            {b.text}
          </Button>
        );
      })}
      {!isEmpty(errors) && (
        <Message
          error
          header={doesntLookRight}
          list={Object.values(errors).flat()}
          icon="warning"
        />
      )}
      {comment && <Message>{comment}</Message>}
    </Segment>
  );
};

export default SubmitButtons;
