import React, { Component } from "react";
import Comment from "./Comment";
import RadioButtons from "./RadioButtons";
import {
  Button,
  Segment,
  Header,
  Input,
  Checkbox,
  Select,
  Form,
  TextArea,
  Message,
} from "semantic-ui-react";
import "./InputAction.css";
import { DateInput } from "semantic-ui-calendar-react";
import {
  mandatoryFieldNote,
  fieldNotSet,
  dateNotSet,
  noNumberEntered,
} from "../../config/copy";
import DropdownDatePicker from "./DropdownDatePicker";
import isIOS from "is-ios";
import { infoToast } from "../../config/toasts";

class InputAction extends Component {
  handleChange = (e, { value, checked, type }) => {
    let val = type !== "checkbox" ? value : checked ? "true" : "false";
    if (type === "date" && isIOS) {
      val = val.split("-").reverse().join("/");
    }
    this.props.handleChange(val);
  };

  copyCodeToClipboard = (text, innerTextOnly) => {
    if (innerTextOnly) {
      text = text.replace(/<.*?>/g, "");
    }
    navigator.clipboard.writeText(text);

    infoToast("Text copied to clipboard.");
  };

  readOnlyValue = (attributes) => {
    if (attributes.field_type === "Textarea") {
      return (
        <>
          <Segment style={{ padding: "0.5rem 0.75rem" }}>
            <div dangerouslySetInnerHTML={{ __html: this.props.value }} />
            {attributes.copyable && (
              <Button
                icon="copy"
                labelPosition="left"
                content="Copy"
                onClick={() => this.copyCodeToClipboard(this.props.value, true)}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "0",
                }}
              />
            )}
          </Segment>
        </>
      );
    }

    let notSetMessage = fieldNotSet;

    if (attributes.field_type === "Date") {
      notSetMessage = dateNotSet;
    } else if (attributes.field_type === "Number") {
      notSetMessage = noNumberEntered;
    }

    return <p>{this.props.value || notSetMessage}</p>;
  };

  getInputField = (attributes) => {
    const inlineDateInput =
      !!window.MSInputMethodContext && !!document.documentMode;
    const nativeDateInputField = isIOS;

    switch (attributes.field_type) {
      case "Dropdown":
        return (
          <Select
            disabled={attributes.disabled}
            style={{ width: "100%" }}
            placeholder={attributes.placeholder}
            value={this.props.value}
            options={attributes.options.map((o) => ({
              text: o.option_text,
              value: o.option_value,
            }))}
            onChange={this.handleChange}
          />
        );
      case "Textarea":
        return attributes.disabled ? (
          this.readOnlyValue(attributes)
        ) : (
          <Form>
            <TextArea
              disabled={attributes.disabled}
              placeholder={attributes.placeholder}
              value={this.props.value}
              onChange={this.handleChange}
              maxLength={
                this.props.maxLength === undefined
                  ? "99999"
                  : this.props.maxLength
              }
              rows={this.props.rows === undefined ? "4" : this.props.rows}
            />
          </Form>
        );
      case "Password":
        return (
          <Input
            disabled={attributes.disabled}
            type="password"
            error={!!attributes.error}
            style={{ minWidth: "50%" }}
            placeholder={attributes.placeholder}
            value={this.props.value === undefined ? "" : this.props.value}
            onChange={this.handleChange}
          />
        );
      case "Number":
        return attributes.disabled ? (
          this.readOnlyValue(attributes)
        ) : (
          <Input
            disabled={attributes.disabled}
            type="number"
            min="0"
            step="1"
            error={!!attributes.error}
            style={{ minWidth: "50%" }}
            placeholder={attributes.placeholder}
            value={this.props.value === undefined ? "" : this.props.value}
            onChange={this.handleChange}
          />
        );
      case "Text":
        return attributes.disabled ? (
          this.readOnlyValue(attributes)
        ) : (
          <Input
            type={attributes.name === "email" ? "email" : undefined}
            disabled={attributes.disabled}
            error={!!attributes.error}
            style={{ minWidth: "50%" }}
            placeholder={attributes.placeholder}
            value={this.props.value === undefined ? "" : this.props.value}
            onChange={this.handleChange}
          />
        );
      case "Checkbox":
        return (
          <Checkbox
            disabled={attributes.disabled}
            label={this.props.label}
            checked={!(this.props.value === "false" || this.props.value === "")}
            onChange={this.handleChange}
            required={attributes.mandatory}
          />
        );
      case "Radio buttons":
        return (
          <RadioButtons
            disabled={attributes.disabled}
            value={this.props.value}
            options={attributes.options}
            onChange={this.handleChange}
          />
        );
      case "Date":
        return attributes.disabled ? (
          this.readOnlyValue(attributes)
        ) : (
          <>
            {nativeDateInputField ? (
              <Input
                type="date"
                placeholder="Date"
                value={
                  this.props.value
                    ? this.props.value.split("/").reverse().join("-")
                    : ""
                }
                onChange={this.handleChange}
              />
            ) : inlineDateInput ? (
              <DropdownDatePicker
                value={this.props.value}
                onChange={this.handleChange}
              />
            ) : (
              <DateInput
                className="date-input-inline"
                inline={inlineDateInput}
                disabled={attributes.disabled}
                closable
                popupPosition={"top left"}
                name={attributes.name}
                placeholder="Date"
                iconPosition="left"
                dateFormat="DD/MM/YYYY"
                value={this.props.value}
                initialDate={this.props.value}
                onChange={this.handleChange}
              />
            )}

            <Button
              onClick={() => this.handleChange(null, { value: "" })}
              basic
              color="red"
              disabled={!this.props.value || !this.props.value.length > 0}
            >
              Clear
            </Button>
          </>
        );

      default:
        break;
    }
  };

  render() {
    const {
      field_type,
      label,
      comment,
      commentColor,
      error,
      mandatory,
      suppress_line_below,
      text_above,
    } = this.props;

    const text_above_header = text_above && (
      <Header as={"h4"}>{text_above}</Header>
    );
    const label_header = label && <Header as={"h4"}>{label}</Header>;
    return (
      <Segment
        style={
          suppress_line_below
            ? { borderTop: "none", borderBottom: "none" }
            : {
                borderBottom: "1px solid rgba(34,36,38,.15)",
                borderTop: "none",
              }
        }
      >
        {field_type !== "Checkbox" ? (
          <>
            {text_above_header}
            {label_header}
          </>
        ) : (
          text_above && <Header as={"h4"}>{text_above}</Header>
        )}
        {this.getInputField(this.props)}
        {error && <Message error content={error} />}
        {mandatory && !this.props.value && (
          <p className="mandatory-field-note">
            <i>{mandatoryFieldNote}</i>
          </p>
        )}
        {comment && <Comment content={comment} color={commentColor} />}
      </Segment>
    );
  }
}

export default InputAction;
